import {
  logoJavascript,
  logoNodejs,
  logoReact,
  logoPython,
  logoVue,
  codeSlashOutline,
  logoHtml5,
  logoCss3,
  accessibilityOutline,
  laptopOutline,
  hammerOutline,
  phonePortrait,
} from "ionicons/icons";

import java from "@/assets/images/icons/java.svg";
import typescript from "@/assets/images/icons/typescript.svg";
import android from "@/assets/images/icons/android.svg";
import apple from "@/assets/images/icons/apple.svg";
import flutter from "@/assets/images/icons/flutter.svg";
import php from "@/assets/images/icons/php.svg";
import rails from "@/assets/images/icons/rails.svg";

export const general = [
  {
    id: "backend",
    title: "Backend",
    color: "#E7E5F2",
  },
  {
    id: "frontend",
    title: "Front-end",
    color: "#F8E4CF",
  },
  {
    id: "mobile",
    title: "Mobile",
    color: "#D0EFD2",
    icon: phonePortrait,
  },
  /*
  {
    id: "android",
    title: "Android",
    color: "#D0EFD2",
    icon: android,
  },
  {
    id: "ios",
    title: "iOS",
    color: "#CBE7FF",
    icon: apple,
  },
  */
  {
    id: "pl",
    title: "Programming",
    color: "#E7F3FF",
    icon: codeSlashOutline,
  },
  {
    id: "qa",
    title: "QA & Testing",
    color: "#E5DBD7",
    icon: hammerOutline,
  },
  {
    id: "ui-ux",
    title: "UI & UX Design",
    color: "#b2dfdb",
  },
  {
    id: "cs",
    title: "Computer Science",
    color: "#fff9c4",
    icon: laptopOutline,
  },
  {
    id: "agile",
    title: "Agile",
    color: "#cfd8dc",
  },
  {
    id: "behavioural",
    title: "Behavioural",
    color: "#E4CEEF",
    icon: accessibilityOutline,
  },
];

export const backend = [
  {
    id: "nodejs",
    title: "Node.js",
    color: "#CBE7FF",
    icon: logoNodejs,
  },
  {
    id: "django",
    title: "Django",
    color: "#D0EFD2",
    icon: logoPython,
  },
  {
    id: "laravel",
    title: "Laravel",
    color: "#F8E4CF",
    icon: php,
  },
  {
    id: "ror",
    title: "Ruby on Rails",
    color: "#DEDAF4",
    icon: rails,
  },
];

export const frontend = [
  {
    id: "react",
    title: "React",
    color: "#DEDAF4",
    icon: logoReact,
    resources: [
      "https://reactjs.org/docs/getting-started.html",
      "https://github.com/enaqx/awesome-react",
      "https://www.udemy.com/courses/search/?lang=en&q=react+js&ratings=4.5&sort=relevance",
      "https://reactpatterns.com",
    ],
  },
  {
    id: "vue",
    title: "Vue",
    color: "#F8E4CF",
    icon: logoVue,
  },
  {
    id: "html",
    title: "HTML",
    color: "#D0EFD2",
    icon: logoHtml5,
  },
  {
    id: "css",
    title: "CSS",
    color: "#F8B3B3",
    icon: logoCss3,
  },
];

export const hybridMobile = [
  {
    id: "react-native",
    title: "React Native",
    color: "#fff9c4",
    icon: logoReact,
  },
  {
    id: "flutter",
    title: "Flutter",
    color: "#CBE7FF",
    icon: flutter,
  },
];

export const mobile = [
  {
    id: "android",
    title: "Android",
    color: "#F8B3B3",
    icon: android,
  },
  {
    id: "ios",
    title: "iOS",
    color: "#cfd8dc",
    icon: apple,
  },
  ...hybridMobile,
];

export const pl = [
  {
    id: "javascript",
    title: "Javascript",
    color: "#DEDAF4",
    icon: logoJavascript,
  },
  {
    id: "typescript",
    title: "Typescript",
    color: "#F8E4CF",
    icon: typescript,
  },
  {
    id: "java",
    title: "Java",
    color: "#D0EFD2",
    icon: java,
  },
  {
    id: "python",
    title: "Python",
    color: "#CBE7FF",
    icon: logoPython,
  },
  {
    id: "php",
    title: "PHP",
    color: "#fff9c4",
    icon: php,
  },
];

export const other = [
  {
    id: "cs",
    title: "Computer Science",
    color: "#fce4ec",
    icon: laptopOutline,
  },
  {
    id: "qa",
    title: "QA & Testing",
    color: "#e0f7fa",
    icon: hammerOutline,
  },
  {
    id: "ui-ux",
    title: "UI & UX Design",
    color: "#b2dfdb",
  },
  {
    id: "agile",
    title: "Agile",
    color: "#cfd8dc",
  },
  {
    id: "behavioural",
    title: "Behavioural",
    color: "#E4CEEF",
    icon: accessibilityOutline,
  },
];

export const allCategories = [
  ...general,
  ...backend,
  ...frontend,
  ...mobile,
  ...pl,
  ...other,
];

export const subCategories = [
  ...backend,
  ...frontend,
  ...mobile,
  ...pl,
  ...other,
];
