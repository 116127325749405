import firebase from "firebase";

export const getUserInfo = async (id?: string) => {
  const db = firebase.firestore();
  const user = firebase.auth().currentUser;

  // console.log(user);

  if (user || id) {
    const result = await db
      .collection("users")
      .doc(id || user!.uid)
      .get();
    return result.data();
  }
};

export const updateUserInfo = async (data: object) => {
  const db = firebase.firestore();
  const user = firebase.auth().currentUser;

  try {
    await db.collection("users").doc(user!.uid).update(data);
  } catch (error) {
    console.log("Error: ", error);
    return error;
  }
};

export const uploadFile = async (file: Blob, name: string) => {
  const storageRef = firebase.storage().ref().child(name);

  try {
    const snapshot = await storageRef.put(file);
    const url = await snapshot.ref.getDownloadURL();

    return url;
  } catch (error) {
    console.log(error);
  }
};

export const deleteFile = async (name: string) => {
  const storageRef = firebase.storage().ref().child(name);

  try {
    storageRef.delete();

    return "success";
  } catch (error) {
    console.log(error);
  }
};

export const getUsers = async () => {
  const db = firebase.firestore();

  const snapshot = await db.collection("users").get();

  return snapshot?.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};
