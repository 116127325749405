/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isPlatform, loadingController } from "@ionic/vue";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";
import { Camera, CameraResultType } from "@capacitor/camera";

import { getItem } from "@/utils/storage";
import { base64toBlob, byteToMB } from "@/utils/file";
import { successAlert, errorAlert } from "@/utils/popups";
import { updateUserInfo, uploadFile, deleteFile } from "@/services/userService";

export const isInfoSkipped = async () => {
  const infoSkipped = await getItem("infoSkipped");
  return infoSkipped;
};

export const timer = (callback: () => void) => {
  setInterval(() => {
    callback();
  }, 60000);
};

export const isArrayFull = (array: any[], length?: number) => {
  return (
    !array.includes(undefined) &&
    !array.includes(null) &&
    array.length === length
  );
};

export const secondsToDHMS = (value: number) => {
  let result = "";
  const seconds = Number(value);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d == 1 ? " day" : " days") : "";
  const hDisplay = h > 0 ? h + (h == 1 ? " hour" : " hours") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " minute" : " minutes") : "";
  const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

  if (dDisplay) result = dDisplay;
  if (hDisplay) result = result ? `${result}, ${hDisplay}` : hDisplay;
  if (mDisplay) result = result ? `${result}, ${mDisplay}` : mDisplay;
  if (sDisplay) result = result ? `${result}, ${sDisplay}` : sDisplay;

  return result;
};

export const getAverage = (array: any[]) => {
  const sum = array.reduce((a, b) => a + b, 0);

  return sum / array.length || 0;
};

export const capitalize = (text: string) => {
  return text?.[0].toUpperCase() + text.slice(1) || "";
};

export const addCodeSupport = () => {
  (document as any)
    .getElementById("code")
    .addEventListener("keydown", function (e: any) {
      if (e.key == "Tab") {
        e.preventDefault();
        //@ts-ignore
        const start = this.selectionStart;
        //@ts-ignore
        const end = this.selectionEnd;

        //@ts-ignore
        this.value = `${this.value.substring(0, start)}\t${this.value.substring(
          end
        )}`;

        //@ts-ignore
        this.selectionStart = this.selectionEnd = start + 1;
      }
    });
};

export const isPremium = () => {
  if (isPlatform("capacitor")) {
    InAppPurchase2.verbosity = InAppPurchase2.DEBUG;

    InAppPurchase2.register({
      id: "devzilla_premium_monthly",
      type: InAppPurchase2.PAID_SUBSCRIPTION,
    });

    InAppPurchase2.refresh();

    InAppPurchase2.when("devzilla_premium_monthly").updated((product: any) => {
      if (product.owned) console.log("product owned");
      else console.log("product not owned");
    });
  }
};

export const uploadProfilePic = async (state: any) => {
  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.Base64,
    promptLabelHeader: "Upload Profile Picture",
    width: 1000,
  });

  const blobData = base64toBlob(image.base64String!, `image/${image.format}`);

  if (byteToMB(blobData.size) > 2) {
    errorAlert("File size can't be larger than 2MB. Please try again");
    return;
  }

  const showLoading = async () => {
    const loading = await loadingController.create({
      spinner: "bubbles",
      message: "Your profile is being updated...",
      translucent: true,
      backdropDismiss: false,
    });

    await loading.present();

    return loading;
  };

  const loading = await showLoading();

  const photo = await uploadFile(
    blobData,
    `images/${new Date().getTime()}.${image.format}`
  );

  if (state.photo) {
    await deleteFile(state.photo);
  }

  state.photo = `data:image/jpeg;base64,${image.base64String}` as string;

  await updateUserInfo({
    photo,
  });

  loading.dismiss();

  return { loading, photo };
};
