import { createStore, useStore as useVuexStore } from "vuex";

import { Question } from "@/types";

type State = {
  searchResults: Array<{ id: string; title: string }>;
  questions: Array<Question>;
  isPremium: boolean;
};

const store = createStore<State>({
  state() {
    return {
      searchResults: [],
      questions: [
        {
          id: "rTUWxWeVUpEGnH2PSXTd",
          description: "",
          subCategory: "php",
          category: "pl",
          answers: ["const", "constant", "define", "def"],
          title: "Which of the following is used to declare a constant?",
          difficulty: "",
          solution:
            "It is possible to define constants on a per-class basis remaining the same and unchangeable. The default visibility of class constants is public.\n\nIt's also possible for interfaces to have constants. Look at the interface documentation for examples.\n\nIt's possible to reference the class using a variable. The variable's value can not be a keyword (e.g. self, parent and static).\n\nNote that class constants are allocated once per class, and not for each class instance.",
          answer: [2],
          userAnswer: 2,
          time: 30,
        },
      ],
      isPremium: false,
    };
  },
  actions: {
    searchResults({ commit }, payload: any) {
      commit("searchResults", payload);
    },
    questions({ commit }, payload: any) {
      commit("questions", payload);
    },
    isPremium({ commit }, payload: any) {
      commit("isPremium", payload);
    },
  },
  mutations: {
    searchResults(state: any, payload: any) {
      state.searchResults = payload;
    },
    questions(state: any, payload: any) {
      state.questions = payload;
    },
    isPremium(state: any, payload: any) {
      state.isPremium = payload;
    },
  },
});

export default store;
export function useStore(): typeof store {
  return useVuexStore();
}
