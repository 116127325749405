import firebase from "firebase";
import { cfaSignIn, cfaSignOut } from "capacitor-firebase-auth";

import { EMAIL_VERIFY_ERROR } from "@/constants/errors";

export const signup = async (email: string, password: string, name: string) => {
  const db = firebase.firestore();
  try {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    const user = response?.user;

    if (user && !user.emailVerified) {
      user.sendEmailVerification().then(function () {
        console.log("email verification sent to user");
      });
    }

    if (!user) return "error";

    db.collection("users").doc(user.uid).set({
      email,
      name,
      date: Date.now(),
      categories: [],
    });

    return "success";
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const login = async (email: string, password: string) => {
  try {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    if (!response?.user?.emailVerified) return EMAIL_VERIFY_ERROR;
    return "success";
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const googleLogin = async () => {
  const db = firebase.firestore();
  try {
    return new Promise((resolve) => {
      cfaSignIn("google.com").subscribe(async (user: any) => {
        if (user) {
          const response: any = await getUserData(user.uid);

          if (!response) {
            const { email, displayName: name } = user;

            await db.collection("users").doc(user.uid).set({
              email,
              name,
              date: Date.now(),
              categories: [],
            });
          }

          resolve("success");
        }
      });
    });
  } catch (error) {
    return error;
  }
};

export const facebookLogin = async () => {
  try {
    const provider = new firebase.auth.GithubAuthProvider();
    const response = await firebase.auth().signInWithPopup(provider);
    const { user } = response;
    if (user) return "success";
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const signout = async () => {
  return new Promise((resolve) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        cfaSignOut().subscribe();
        resolve(true);
      })
      .catch((error: string) => console.log(error));
  });
};

export const SendEmailVerification = async () => {
  try {
    firebase.auth().currentUser?.sendEmailVerification();
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const resetPassword = async (email: string) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return "success";
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const getUserData = async (id: string) => {
  const db = firebase.firestore();
  const result = await db.collection("users").doc(id).get();

  return result?.data();
};
