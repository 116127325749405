import { createApp } from "vue";
import firebase from "firebase";
import {
  IonicVue,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonSpinner,
  IonAvatar,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonContent,
  IonPage,
  isPlatform,
} from "@ionic/vue";
import MiniSearch from "minisearch";
import "highlight.js/styles/stackoverflow-light.css";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import hljsVuePlugin from "@highlightjs/vue-plugin";
import { createAnimation } from "@ionic/vue";
/*
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
*/
import Hammer from "hammerjs";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  general,
  backend,
  frontend,
  mobile,
  pl,
  other,
} from "@/constants/categories";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

/* Global Styles */
import "./styles.scss";

const contentful = require("contentful");

const categories = [
  ...general,
  ...backend,
  ...frontend,
  ...mobile,
  ...pl,
  ...other,
];

const config = {
  swipeBackEnabled: false,
  backButtonText: null,
  animated: true,
  navAnimation: () => createAnimation(),
};

hljs.registerLanguage("javascript", javascript);

const app = createApp(App);

const isDesktop = isPlatform("desktop");

app.use(IonicVue, config);
app.use(router);
app.use(store);
app.use(hljsVuePlugin);

const contentfulClient = contentful.createClient({
  space: "n5iaod7t9ukr",
  environment: "master",
  accessToken: "59teypWqJM5lZU_iEokEOHrdUUWkaIGEW3_1J3BtVMM",
});

/*
Sentry.init({
  app,
  dsn: "https://32801564cf714a5c9e39b13c706bf156@o320758.ingest.sentry.io/6320491",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/

const firebaseClient = firebase.initializeApp({
  apiKey: "AIzaSyDohAPMWgVretZW5lRYvGQdFGC-D7dqj50",
  authDomain: "devzilla-3af7c.firebaseapp.com",
  projectId: "devzilla-3af7c",
  storageBucket: "devzilla-3af7c.appspot.com",
  messagingSenderId: "354228320377",
  appId: "1:354228320377:web:6f03c2189d79dfea193ca8",
});

firebase.auth().languageCode = "tr";

const miniSearch = new MiniSearch({
  fields: ["title"], // fields to index for full-text search
  storeFields: ["id", "title"], // fields to return with search results
});

miniSearch.addAll(categories);

app.config.globalProperties.$contentfulClient = contentfulClient;
app.config.globalProperties.$firebaseClient = firebaseClient;
app.config.globalProperties.$miniSearch = miniSearch;
app.config.globalProperties.$isDesktop = isDesktop;

app.component("ion-page", IonPage);
app.component("ion-content", IonContent);
app.component("ion-icon", IonIcon);
app.component("ion-card", IonCard);
app.component("ion-card-content", IonCardContent);
app.component("ion-card-header", IonCardHeader);
app.component("ion-card-title", IonCardTitle);
app.component("ion-card-subtitle", IonCardSubtitle);
app.component("ion-item", IonItem);
app.component("ion-label", IonLabel);
app.component("ion-select", IonSelect);
app.component("ion-select-option", IonSelectOption);
app.component("ion-button", IonButton);
app.component("ion-spinner", IonSpinner);
app.component("ion-avatar", IonAvatar);
app.component("ion-img", IonImg);
app.component("ion-refresher", IonRefresher);
app.component("ion-refresher-content", IonRefresherContent);

router.isReady().then(() => {
  app.mount("#app");
});
