import { isPlatform } from "@ionic/vue";
import { App } from "@capacitor/app";
import { codePush, InstallMode } from "capacitor-codepush";
import {
  IRemotePackage,
  ILocalPackage,
} from "capacitor-codepush/dist/esm/package";

export const initAppCenter = () => {
  const success = async (remotePackage: IRemotePackage) => {
    if (!remotePackage) {
      console.log("App is up-to-date");
      codePush.notifyApplicationReady(); // checks if the last update was successful,(if not successful, it will rollback to previous version)
    } else {
      if (!remotePackage.failedInstall) {
        console.log(
          "A CodePush update is available. Package hash: ",
          remotePackage
        );
        // DOWNLOAD UPDATE
        console.log("Downloading ====>");
        const result: ILocalPackage = await remotePackage.download();

        if (result) {
          result.install({
            installMode: InstallMode.ON_NEXT_RESTART,
            minimumBackgroundDuration: 0,
            mandatoryInstallMode: InstallMode.IMMEDIATE,
          });
        }
        console.log("Result of download", result);
      } else {
        console.log("The available update was attempted before and failed.");
      }
    }
  };

  const onPackageDownloaded = (localPackage: ILocalPackage) => {
    console.log("Download succeeded ====>", localPackage.description);
    localPackage
      .install({
        installMode: InstallMode.IMMEDIATE,
        minimumBackgroundDuration: 0,
        mandatoryInstallMode: InstallMode.IMMEDIATE,
      })
      .then(onInstallSuccess, error);
  };

  const onInstallSuccess = () => {
    console.log("Installation succeeded.");

    setTimeout(async () => {
      codePush.restartApplication(); // restarts the application to patch the update
    }, 200);
  };

  const error = (error: string) => {
    console.log("Error ====>", error);
  };

  const checkUpdate = () => {
    let deploymentKey;

    if (isPlatform("android")) {
      deploymentKey = process.env.codepushAndroidKey;
    }

    codePush.checkForUpdate(success as any, error as any, deploymentKey);
  };

  checkUpdate(); //Checks update on application start.

  App.addListener("appStateChange", ({ isActive }) => {
    console.log("isActive: ", isActive);
    if (isActive) {
      checkUpdate(); //checks update when app was paused then resumes active state.
    }
  });
};
