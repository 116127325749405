import { isPlatform } from "@ionic/vue";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";

import EventEmitter from "@/EventEmitter";
import { IAP_VALIDATOR } from "@/constants/general";

const products = [
  "devzilla_premium_weekly",
  "devzilla_premium_monthly",
  "devzilla_premium_annual",
];

export const initIAP = () => {
  if (isPlatform("capacitor")) {
    InAppPurchase2.verbosity = InAppPurchase2.ERROR;

    InAppPurchase2.validator = IAP_VALIDATOR;

    InAppPurchase2.register([
      {
        id: products[0],
        type: InAppPurchase2.PAID_SUBSCRIPTION,
      },
      {
        id: products[1],
        type: InAppPurchase2.PAID_SUBSCRIPTION,
      },
      {
        id: products[2],
        type: InAppPurchase2.PAID_SUBSCRIPTION,
      },
    ]);

    InAppPurchase2.when("subscription")
      .registered((p: any) => console.log(p))
      .updated((p: any) => {
        // console.log("Subscription Updated: ", p);
        EventEmitter.emit("premium-checked", p.owned);
      })
      .approved((p: any) => {
        p.verify();
      })
      .verified(async (p: any) => {
        // console.log("Subscription Verified!");
        p.finish();
      })
      .owned(() => {
        EventEmitter.emit("premium-checked", true);
      })
      .expired(() => {
        // console.log("Subscription Expired!");
        EventEmitter.emit("premium-checked", false);
      })
      .cancelled((response: any) => console.log(response))
      .error((response: any) => {
        console.log(response);
      });

    InAppPurchase2.refresh();

    InAppPurchase2.ready(() => {
      // console.log(InAppPurchase2.products);
    });
  }
};
