import { Storage } from "@capacitor/storage";

export const setObject = async (key: string, value: object) => {
  await Storage.set({
    key,
    value: JSON.stringify(value),
  });
};

export const getObject = async (key: string) => {
  const data = await Storage.get({ key });
  const result = JSON.parse(data.value!);
  return result;
};

export const setItem = async (key: string, value: string) => {
  await Storage.set({
    key,
    value,
  });
};

export const getItem = async (key: string) => {
  const { value } = await Storage.get({ key });
  return value;
};

export const removeItem = async (key: string) => {
  await Storage.remove({ key });
};

export const keys = async () => {
  const { keys } = await Storage.keys();
  console.log("Got keys: ", keys);
};

export const clear = async () => {
  await Storage.clear();
};
