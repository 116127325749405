
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  IonAvatar,
  menuController,
  useBackButton,
  useIonRouter,
  isPlatform,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import firebase from "firebase";
import { ThemeDetection } from "@awesome-cordova-plugins/theme-detection";
import {
  heartOutline,
  heartSharp,
  homeOutline,
  homeSharp,
  personOutline,
  personSharp,
  listOutline,
  listSharp,
  settingsOutline,
  settingsSharp,
  gameControllerOutline,
  gameControllerSharp,
  rocketOutline,
  rocketSharp,
  optionsOutline,
  optionsSharp,
  ribbonOutline,
  ribbonSharp,
} from "ionicons/icons";
import { SplashScreen } from "@capacitor/splash-screen";

import { signout, getUserData } from "@/services/authService";
import { timer } from "@/utils/general";
import { updateUserInfo } from "@/services/userService";
import { setItem, getItem } from "@/utils/storage";
import { toggleDarkMode } from "@/utils/ui";
import EventEmitter from "@/EventEmitter";
import { initIAP } from "@/utils/iap";
import { useStore } from "@/store";
import { initAppCenter } from "@/utils/app-center";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonAvatar,
  },
  setup() {
    const isDesktop = isPlatform("desktop");
    const selectedIndex = ref(0);
    const name = ref("");
    const photo = ref("");
    const isAdmin = ref(false);

    const staticAppPages = [
      {
        title: "Home",
        url: "/",
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      },
      {
        title: "Profile",
        url: "/profile",
        iosIcon: personOutline,
        mdIcon: personSharp,
      },
      /*
      {
        title: "Articles",
        url: "/articles",
        iosIcon: listOutline,
        mdIcon: listSharp,
      },
      {
        title: "Learning Center",
        url: "/learning-center",
        iosIcon: bookOutline,
        mdIcon: bookSharp,
      },
      */
      {
        title: "Interview Preparation",
        url: "/interview-preparation",
        iosIcon: rocketOutline,
        mdIcon: rocketSharp,
      },
      {
        title: "Quiz Game",
        url: "/quiz",
        iosIcon: gameControllerOutline,
        mdIcon: gameControllerSharp,
      },
      /*
      {
        title: "My Favorites",
        url: "/favorites",
        iosIcon: heartOutline,
        mdIcon: heartSharp,
      },
      */
      {
        title: "Admin",
        url: "/admin",
        iosIcon: optionsOutline,
        mdIcon: optionsSharp,
      },
      {
        title: "Settings",
        url: "/settings",
        iosIcon: settingsOutline,
        mdIcon: settingsSharp,
      },
      {
        title: "Upgrade to Premium",
        url: "/upgrade",
        iosIcon: ribbonOutline,
        mdIcon: ribbonSharp,
      },
    ];

    if (isDesktop) staticAppPages.pop();

    const appPages = ref([...staticAppPages]);

    const route = useRoute();
    const router = useRouter();
    const ionRouter = useIonRouter();
    const store = useStore();
    const { path } = route;

    router.afterEach((to) => {
      if (to.path !== undefined) {
        const index = appPages.value.findIndex(
          (page) => page.url === to.path.toLowerCase()
        );

        if (index >= 0) selectedIndex.value = index;
      }
    });

    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        console.log("Back pressed!");
      }
    });

    const logout = async () => {
      await signout();
      await setItem("loggedIn", "false");
      menuController.close();
      router.push("/auth");
    };

    onMounted(async () => {
      if (isPlatform("capacitor")) {
        initAppCenter();
        await SplashScreen.hide();
      }

      timer(async () => {
        const activeTime = await getItem("activeTime");

        await setItem(
          "activeTime",
          activeTime !== null ? (parseInt(activeTime) + 1).toString() : "0"
        );
      });
    });

    EventEmitter.on("image-uploaded", async (value: boolean) => {
      if (value) {
        init();
      }
    });

    EventEmitter.on("premium-checked", async (value: boolean) => {
      if (value) {
        // EventEmitter.off("premium-checked");
        store.dispatch("isPremium", true);

        togglePremium(true);

        updateUserInfo({
          premium: true,
        });

        checkDarkMode();
      } else {
        // EventEmitter.off("premium-checked");

        updateUserInfo({
          premium: false,
        });

        togglePremium(false);
      }
    });

    const checkDarkMode = async () => {
      let prefersDark = false;

      // const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
      const isAvailable = await ThemeDetection.isAvailable();

      if (isAvailable) {
        prefersDark = (await ThemeDetection.isDarkModeEnabled()).value;
      }

      const darkMode = (await getItem("darkMode")) === "true";

      toggleDarkMode(prefersDark || darkMode);
    };

    const init = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const response: any = await getUserData(user.uid);
          if (response?.name) name.value = response.name;
          if (response?.photo) photo.value = response.photo;
          if (response?.premium) togglePremium(true);

          appPages.value = appPages.value.filter(
            (appPage) => appPage.url !== "/admin" || response?.isAdmin
          );
        } else {
          logout();
          appPages.value = staticAppPages;
        }

        initIAP();
      });
    };

    init();

    const togglePremium = (isPremium: boolean) => {
      const premiumShowed = (appPages.value as any).at(-1).url === "/upgrade";

      // Premium tab showed in side menu but user is already premium
      if (isPremium && premiumShowed) {
        appPages.value.pop();
      }
      // Premium tab not showed in side menu but user is not premium yet
      else if (!isPremium && !premiumShowed) {
        appPages.value.push((staticAppPages as any).at(-1));
      }
    };

    if (path !== undefined) {
      const index = appPages.value.findIndex(
        (page) => page.url === path.toLowerCase()
      );

      if (index >= 0) selectedIndex.value = index;
    }

    const goToProfile = () => {
      router.push("/profile");
      menuController.close();
    };

    return {
      selectedIndex,
      appPages,
      logout,
      menuController,
      isAdmin,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      name,
      photo,
      goToProfile,
      isDesktop,
    };
  },
});
