import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { menuController } from "@ionic/vue";

import { isInfoSkipped } from "@/utils/general";
import { isLoggedIn } from "@/utils/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth",
    component: () => import("../views/Auth.vue"),
    meta: {
      isAuth: true,
    },
  },
  {
    path: "/profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/:id",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/favorites",
    component: () => import("../views/Favorites.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/articles",
    component: () => import("../views/Articles.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/learning-center",
    component: () => import("../views/LearningCenter.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/interview-preparation",
    component: () => import("../views/InterviewPrep.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/interview-preparation/:id",
    component: () => import("../views/InterviewPrep.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/quiz",
    component: () => import("../views/Quiz/index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [],
  },
  {
    path: "/quiz/game",
    component: () => import("../views/Quiz/Game.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/quiz/answers",
    component: () => import("../views/Quiz/Answers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-info",
    component: () => import("../views/UserInfo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    component: () => import("../views/About.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/references",
    component: () => import("../views/References.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/welcome",
    component: () => import("../views/Welcome.vue"),
    meta: {
      isInfo: true,
    },
  },
  {
    path: "/categories",
    component: () => import("../views/Categories.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/category/:id",
    component: () => import("../views/Category.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search-results",
    component: () => import("../views/SearchResults.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/upgrade",
    component: () => import("../views/Upgrade.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    component: () => import("../views/Admin/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/article",
    component: () => import("../views/Admin/Article.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/featured",
    component: () => import("../views/Admin/Featured.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/user",
    component: () => import("../views/Admin/User.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/learning-center",
    component: () => import("../views/Admin/LearningCenter.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/interview-preparation",
    component: () => import("../views/Admin/InterviewPrep.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/quiz",
    component: () => import("../views/Admin/Quiz.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  to.matched.some(async (record) => {
    const infoSkipped = await isInfoSkipped();
    const loggedIn = await isLoggedIn();

    menuController.swipeGesture(!record.meta.menuDisabled);

    if (record.meta.requiresAuth) {
      if (loggedIn !== "true") {
        //if (infoSkipped !== "true") next("/welcome");
        //else next("/auth");
        next("/auth");
      } else {
        if (infoSkipped !== "true") next("/welcome");
        else next();
      }
    } else if (record.meta.isAuth) {
      if (loggedIn === "true") next("/");
      else next();
    } else {
      next();
    }
  });
});

export default router;
