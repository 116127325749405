import { alertController } from "@ionic/vue";

export const errorAlert = async (message?: string) => {
  const alert = await alertController.create({
    header: "Error",
    message: message || "Please make sure you have filled all fields correctly",
    buttons: ["Okay"],
  });
  return alert.present();
};

export const successAlert = async () => {
  const alert = await alertController.create({
    header: "Success",
    message: "Successfully added!",
    buttons: ["Okay"],
  });
  return alert.present();
};

export const featureMissing = async () => {
  const alert = await alertController.create({
    header: "Coming Soon",
    message: "This feature is not available at the moment.",
    buttons: ["Okay"],
  });
  return alert.present();
};

export const premiumSuccess = async () => {
  const alert = await alertController.create({
    header: "Success",
    message: "You could purchase the premium package successfully.",
    buttons: ["Okay"],
  });
  return alert.present();
};

export const generalError = async () => {
  const alert = await alertController.create({
    header: "Error",
    message: "Something unexpected happened. Please try again!",
    buttons: ["Okay"],
  });
  return alert.present();
};

export const noSolution = async () => {
  const alert = await alertController.create({
    header: "No Solution",
    message: "There isn't a solution for this question.",
    buttons: ["Okay"],
  });
  return alert.present();
};

export const hideLearningPath = async () => {
  const alert = await alertController.create({
    header: "Are you sure?",
    message:
      "Do you really want to hide this card? You can still see it under the options menu.",
    buttons: [
      "Cancel",
      {
        text: "Okay",
        handler: () => {
          console.log("Confirm Ok");
        },
      },
    ],
  });
  return alert.present();
};

export const noQuestion = async () => {
  const alert = await alertController.create({
    header: "No Question",
    message: "There isn't any question for this category yet.",
    buttons: ["Okay"],
  });
  return alert.present();
};
